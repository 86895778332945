<template>
    <div>
        <div class="tab_title">隐私政策</div>
        <div class="tab-text">

            <p style="margin-top:10px;margin-bottom:10px;text-indent:37px;text-align:center">
                <strong><span style=";font-weight:bold;font-size:19px"><span>目</span> &nbsp;&nbsp;&nbsp;</span></strong><strong><span style=";font-weight:bold;font-size:19px">录</span></strong><strong></strong>
            </p>
            <p>
                <span><span style=";">前言</span></span>
            </p>
            <p>
                <span style=";">一、驼鱼商城如何收集和使用您的信息</span>
            </p>
            <p>
                <span style=";"><span>二、驼鱼商城如何使用</span> Cookie 和同类技术</span>
            </p>
            <p>
                <span style=";">三、驼鱼商城如何共享、转让、公开披露您的信息</span>
            </p>
            <p>
                <span style=";">四、驼鱼商城如何保护和保存您的信息</span>
            </p>
            <p>
                <span style=";">五、您如何管理您的信息</span>
            </p>
            <p>
                <span style=";">六</span><span style=";">、通知和修订</span>
            </p>
            <p>
                <span style=";">七、如何联系驼鱼商城</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";">&nbsp;</span>
            </p>
            <h1 style="margin-top:10px;margin-bottom:10px;text-indent:32px;text-align:center">
                <a href="http://undefined"></a><strong><span style=";font-weight:bold;">前言</span></strong><strong></strong>
            </h1>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <strong><span style=";font-weight:bold;">山东企创电子技术有限公司</span></strong><strong><span style=";font-weight:bold;"><span>（</span>“</span></strong><strong><span style=";font-weight:bold;">企创</span></strong><strong><span style=";font-weight:bold;">”）非常重视用户的隐私和信息保护。您在使用驼鱼商城</span></strong><strong><span style=";font-weight:bold;"><span>（包含驼鱼商城</span>PC端、驼鱼商城APP、驼鱼商城小程序及驼鱼商城其他产品或服务）</span></strong><strong><span style=";font-weight:bold;">时，驼鱼商城可能会收集和使用您的相关信息。驼鱼商城</span></strong><strong><span style=";font-weight:bold;">将</span></strong><strong><span style=";font-weight:bold;">通过《</span></strong><strong><span style=";font-weight:bold;">济南企创信息技术咨询服务有限公司</span></strong><strong><span style=";font-weight:bold;">隐私政策》（</span></strong><strong><span style=";font-weight:bold;">以下简称：</span></strong><strong><span style=";font-weight:bold;">“本隐私政策”）向您说明</span></strong><strong><span style=";font-weight:bold;">，</span></strong><strong><span style=";font-weight:bold;">在您使用驼鱼商城时如何收集、使用、保存、共享和转让这些信息，以及驼鱼商城为您提供的访问、更新、删除和保护这些信息的方式。</span></strong><strong></strong>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <strong><span style=";font-weight:bold;"><span>本隐私政策与您所使用的驼鱼商城服务以及该服务所包括的各种业务功能（以下统称</span>“驼鱼商城”）</span></strong><strong><span style=";font-weight:bold;">存在关联关系</span></strong><strong><span style=";font-weight:bold;">，</span></strong><strong><span style=";font-weight:bold;">您在使用驼鱼商城前请务必仔细阅读，并充分理解本隐私政策中的条款内容（尤其是加粗部分，因为这些条款可能涉及免除驼鱼商城责任及明确您应履行的义务或对您的权利进行限制等内容）。如您对本隐私政策内容有任何疑问，请您暂停后续操作并与驼鱼商城客户服务人员（相关联系方式已经在驼鱼商城官网公告）进行咨询并充分理解后再继续使用驼鱼商城。</span></strong><strong><span style=";font-weight:bold;">您使用</span></strong><strong><span style=";font-weight:bold;">驼鱼商城</span></strong><strong><span style=";font-weight:bold;"><span>或在驼鱼商城更新本隐私政策后（驼鱼商城会及时提示您更新的情况）继续使用驼鱼商城，即意味着您同意本隐私政策</span>(含更新版本)内容，并且同意驼鱼商城按照本隐私政策收集、使用、保存和共享您的相关信息。</span></strong><strong></strong>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <strong><span style=";font-weight:bold;">如对本隐私政策或相关事宜有任何问题，您可随时通过拨打驼鱼商城的客服电话</span></strong><strong><span style=";font-weight:bold;">及驼鱼商城公布其他服务方式</span></strong><strong><span style=";font-weight:bold;">与驼鱼商城</span></strong><strong><span style=";font-weight:bold;">进行</span></strong><strong><span style=";font-weight:bold;">联系。</span></strong><strong></strong>
            </p>
            <h1 style="margin-top:10px;margin-bottom:10px;text-indent:32px;text-align:center">
                <a href="http://undefined"></a><strong><span style=";font-weight:bold;">一、驼鱼商城如何收集和使用您的用户信息</span></strong><strong></strong>
            </h1>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";">用户信息是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定企业用户身份或者反映特定企业用户活动情况的各种信息。本隐私政策中涉及的信息包括：企业用户基本信息（包括企业名称、经营范围、经营状况、企业住址、企业电话号码、电子邮箱等）；</span><span style=";">企业用户账号</span><span style=";"><span>网络身份标识信息（包括系统账号、</span>IP地址、邮箱地址及与前述有关的密码、口令、口令保护答案）；</span><span style=";">企业用户账号</span><span style=";">财产信息（交易</span><span style=";">记录、</span><span style=";">消费记录</span><span style=";">、</span><span style=";">余额</span><span style=";">、提现</span><span style=";">等</span><span style=";">与</span><span style=";">财产</span><span style=";">有关</span><span style=";">信息）；企业用户账号通讯录；企业用户账号上网记录（包括网站浏览记录、软件使用记录、点击记录）；</span><span style=";">企业用户账号</span><span style=";"><span>常用设备信息（包括硬件型号、设备</span>MAC地址、操作系统类型、软件列表唯一设备识别码（如IMEI/android ID/IDFA/OPENUDID/GUID、SIM卡IMSI信息等在内的描述</span><span style=";">企业用户账号</span><span style=";">常用设备基本情况的信息）；</span><span style=";">企业用户账号</span><span style=";">位置信息（包括行程信息、精准定位信息、住宿信息、经纬度等）。</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <strong><span style=";font-weight:bold;">驼鱼商城仅会出于以下目的，收集和使用您的企业用户信息：</span></strong><strong></strong>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <strong><span style=";font-weight:bold;">（一）您</span></strong><strong><span style=";font-weight:bold;">须</span></strong><strong><span style=";font-weight:bold;">授</span></strong><strong><span style=";font-weight:bold;">权</span></strong><strong><span style=";font-weight:bold;">驼鱼商城收集和使用您的企业用户信息的情形</span></strong><strong></strong>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";">驼鱼商城包括一些核心功能，这些功能包含了实现</span><span style=";"><span>驼鱼商城</span>API接口商城业务系统</span><span style=";">所必须的功能、改进驼鱼商城所必须的功能及保障交易安全所必须的功能。驼鱼商城可能会收集、保存和使用下列与您有关的信息才能实现上述这些功能。如果您不提供相关信息，您将无法享受驼鱼商城</span><span style=";">的相关功能</span><span style=";">。这些功能包括：</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <strong><span style=";font-weight:bold;">1</span></strong><strong><span style=";font-weight:bold;">、</span></strong><strong><span style=";font-weight:bold;">实现</span></strong><strong><span style=";font-weight:bold;"><span>驼鱼商城</span>API接口服务</span></strong><strong><span style=";font-weight:bold;">所必</span></strong><strong><span style=";font-weight:bold;">须</span></strong><strong><span style=";font-weight:bold;">的功能</span></strong><strong></strong>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";">（</span><span style=";">1</span><span style=";">）用</span><span style=";">户</span><span style=";">注册</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";">您首先需要</span><span style=";">取得</span><span style=";">一个驼鱼商城账户成为驼鱼商城用户。当您注册时，您需要至少向驼鱼商城提供您准备使用的驼鱼商城账户名、密码、您</span><span style=";">登录用的</span><span style=";">手机号码、</span><span style=";">企业法人统一社会信用代码、营业执照图片等企业基本信息，</span><span style=";"><span>这些信息均属于您的</span>“账户信息”。</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";">（</span><span style=";">2</span><span style=";">）商品信息展示和搜索</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";"><span>为了让您快速地找到您所需要的商品，驼鱼商城可能会收集您使用驼鱼商城的设备信息（包括设备名称、设备型号、设备识别码、操作系统和应用程序版本、语言设置、分辨率、服务提供商网络</span>ID（PLMN））、浏览器类型来为您提供商品信息展示的最优方式。驼鱼商城也会为了不断改进和优化上述的功能来使用您的上述企业用户账号信息。</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";">您也可以通过搜索来精准地找到您所需要的商品或服务。驼鱼商城会保留您的搜索内容以方便您重复输入或为您展示与您搜索内容相关联的商品。请您注意，您的搜索关键词信息无法单独识别您的身份，不属于您的</span><span style=";">企业用户账号</span><span style=";">信息，驼鱼商城有权以任何的目的对其进行使用；只有当您的搜索关键词信息与您的其他信息相互结合使用并可以识别您的身份时，则在结合使用期间，驼鱼商城会将您的搜索关键词信息作为您的</span><span style=";">企业用户账号</span><span style=";">信息，与您的搜索历史记录一同按照本隐私政策对其进行处理与保护。</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";">（</span><span style=";">3</span><span style=";">）下</span><span style=";">单</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";">当您准备对您系统内的商品进行结算时，驼鱼商城会生成您购买该商品的订单。您需要在订单中至少填写您的收货人姓名、收货地址以及手机号码</span><span style=";">、运输方式等</span><span style=";">，同时该订单中会载明订单号、您所购买的商品或服务信息、您应支付的货款金额</span><span style=";">及运输费用和</span><span style=";">支付方式。</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";"><span>上述所有信息构成您的</span>“订单信息”，驼鱼商城将使用您下单的订单信息来进行您的身份核验、确定交易、支付结算、完成配送、为您查询订单以及提供客服咨询与售后服务；驼鱼商城还会使用您的订单信息来判断您的交易是否存在异常以保护您的交易安全。</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";">（</span><span style=";">4</span><span style=";">）支付功能</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";">在您下单后，您可以选择与驼鱼商城合作的第三方支付机构（包括</span><span style=";">支付宝、微信、</span><span style=";"><span>银联、通联支付等支付通道，</span> <span>以下称</span>“支付机构”）所提供的支付服务。支付功能本身并不收集您的用户信息，但驼鱼商城需要将您的驼鱼商城订单号与交易金额信息与这些支付机构共享以实现其确认您的支付指令并完成支付。</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";">（</span><span style=";">5</span><span style=";">）交付</span><span style=";">产</span><span style=";">品或服</span><span style=";">务</span><span style=";">功能</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";">在当您下单并完成支付后，与驼鱼商城合作的供应链及第三方配送公司将为您完成订单的出库、运输、交付。您知晓并同意与驼鱼商城合作的第三方公司会在上述环节内使用您的订单信息以保证您的订购的商品能够安全送达。</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";">与驼鱼商城合作的第三方配送公司的配送员在为您提供配送服务的同时，基于某些业务法律要求实名认证的需求，会协助您完成实名认证。驼鱼商城在此环节会使用您的</span><span style=";">基本信息</span><span style=";">通过国家有权认证机构的专有设备对您的身份进行核验，驼鱼商城并没有收集您的身份识别信息，且与驼鱼商城合作的第三方配送公司的配送员均须遵守</span><span style=";">第三方配送公司</span><span style=";">保密制度的规定。</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";">（</span><span style=";">6</span><span style=";">）客服与售后功能</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";">驼鱼商城的电话客服和售后功能会使用您的账号信息和订单信息。</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";">为保证您的账号安全，驼鱼商城的呼叫中心客服和在线客服会使用您的账号信息与您核验您的身份。当您需要驼鱼商城提供与您订单信息相关的客服与售后服务时，驼鱼商城将会查询您的订单信息。您有可能会在与驼鱼商城的客服人员沟通时，提供给出上述信息外的其他信息。</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <strong><span style=";font-weight:bold;">2</span></strong><strong><span style=";font-weight:bold;">、改</span></strong><strong><span style=";font-weight:bold;">进</span></strong><strong><span style=";font-weight:bold;">驼鱼商城所必</span></strong><strong><span style=";font-weight:bold;">须</span></strong><strong><span style=";font-weight:bold;">的功能</span></strong><strong></strong>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";">驼鱼商城可能会收集您的订单信息、浏览信息进行数据分析以形成用户分析，用来将您感兴趣的商品或服务信息展示给您；或在您搜索时向您展示您可能希望找到的商品。驼鱼商城还可能为了提供服务及改进服务质量的合理需要而获得的您的其他信息，包括您与客服联系时您提供的相关信息，您参与问卷调查时向驼鱼商城发送的问卷答复信息，以及您与驼鱼商城的关联方、驼鱼商城合作伙伴之间互动时驼鱼商城获得的相关信息。对于从您的各种设备上收集到的信息，驼鱼商城可能会将它们进行关联，以便驼鱼商城能在这些设备上为您提供一致的服务。驼鱼商城可能会将来自某项服务的信息与来自其他服务的信息结合起来，以便为您提供服务、个性化内容和建议。</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <strong><span style=";font-weight:bold;">3</span></strong><strong><span style=";font-weight:bold;">、保障交易安全所必</span></strong><strong><span style=";font-weight:bold;">须</span></strong><strong><span style=";font-weight:bold;">的功能</span></strong><strong></strong>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";"><span>为提高您使用驼鱼商城时系统的安全性，更准确地预防钓鱼网站欺诈和保护账户安全，驼鱼商城可能会通过了解您的浏览信息、订单信息、您常用的软件信息、设备信息等手段来判断您的账号风险，并可能会记录一些驼鱼商城认为有风险的链接（</span>“URL”）；驼鱼商城也会收集您的设备信息对于驼鱼商城问题进行分析、统计流量并排查可能存在的风险、在您选择向驼鱼商城发送异常信息时予以排查。</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <strong><span style=";font-weight:bold;">（</span></strong><strong><span style=";font-weight:bold;">二</span></strong><strong><span style=";font-weight:bold;">）您充分知</span></strong><strong><span style=";font-weight:bold;">晓</span></strong><strong><span style=";font-weight:bold;">，以下情形中，驼鱼商城收集、使用</span></strong><strong><span style=";font-weight:bold;">企业用户账号</span></strong><strong><span style=";font-weight:bold;">信息无需征得您的授</span></strong><strong><span style=";font-weight:bold;">权</span></strong><strong><span style=";font-weight:bold;">同意：</span></strong><strong></strong>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <strong><span style=";font-weight:bold;">1、与国家安全、国防安全有关的；</span></strong><strong></strong>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <strong><span style=";font-weight:bold;">2、与公共安全、公共卫生、重大公共利益有关的；</span></strong><strong></strong>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <strong><span style=";font-weight:bold;">3、与犯罪侦查、起诉、审判和判决执行等有关的；</span></strong><strong></strong>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <strong><span style=";font-weight:bold;">4、出于维护个人、企业信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</span></strong><strong></strong>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <strong><span style=";font-weight:bold;">5、所收集的用户信息是用户信息主体自行向社会公众公开的；</span></strong><strong></strong>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <strong><span style=";font-weight:bold;">6、从合法公开披露的信息中收集的您的用户信息的，如合法的新闻报道、政府信息公开等渠道；</span></strong><strong></strong>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <strong><span style=";font-weight:bold;">7、根据您的要求签订合同所必需的；</span></strong><strong></strong>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <strong><span style=";font-weight:bold;">8、用于维护</span></strong><strong><span style=";font-weight:bold;">驼鱼商城</span></strong><strong><span style=";font-weight:bold;">的安全稳定运行所必需的，例如发现、处置</span></strong><strong><span style=";font-weight:bold;">驼鱼商城商品</span></strong><strong><span style=";font-weight:bold;">的故障；</span></strong><strong></strong>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <strong><span style=";font-weight:bold;">9、为合法的新闻报道所必需的；</span></strong><strong></strong>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <strong><span style=";font-weight:bold;">10、学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的用户信息进行去标识化处理的；</span></strong><strong></strong>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <strong><span style=";font-weight:bold;">11、法律</span></strong><strong><span style=";font-weight:bold;">、行政</span></strong><strong><span style=";font-weight:bold;">法规</span></strong><strong><span style=";font-weight:bold;">及规范性文件</span></strong><strong><span style=";font-weight:bold;">规定的其他情形。</span></strong><strong></strong>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <strong><span style=";font-weight:bold;">（</span></strong><strong><span style=";font-weight:bold;">三</span></strong><strong><span style=";font-weight:bold;">）驼鱼商城从第三方</span></strong><strong><span style=";font-weight:bold;">获</span></strong><strong><span style=";font-weight:bold;">得您的</span></strong><strong><span style=";font-weight:bold;">企业用户账号</span></strong><strong><span style=";font-weight:bold;">信息的情形</span></strong><strong></strong>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";">驼鱼商城可能从第三方获取您授权共享的账户信息，并在您同意本隐私政策后将您的第三方账户与您的驼鱼商城账户绑定，使您可以通过第三方账户直接登录并使用驼鱼商城。驼鱼商城会将依据与第三方的约定、对</span><span style=";">企业用户账号</span><span style=";">信息来源的合法性进行确认后，在符合相关法律和法规规定的前提下，使用您的这些信息。</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <strong><span style=";font-weight:bold;">（</span></strong><strong><span style=";font-weight:bold;">四</span></strong><strong><span style=";font-weight:bold;">）您的用户信息使用的</span></strong><strong><span style=";font-weight:bold;">规则</span></strong><strong></strong>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";">1、驼鱼商城会根据本隐私政策的约定并为实现驼鱼商城功能对所收集的</span><span style=";">企业用户账号</span><span style=";">信息进行使用。</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";">2、在收集您的信息后，驼鱼商城将通过技术手段对数据进行去标识化处理，去标识化处理的信息将无法识别主体。请您了解并同意，在此情况下驼鱼商城有权使用已经去标识化的信息；并在不透露您信息的前提下，驼鱼商城有权对用户数据库进行分析并予以适当的商业化利用。</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";">3、请您注意，您在使用驼鱼商城时所提供的所有信息，除非您删除或通过系统设置拒绝驼鱼商城收集，否则将在您使用驼鱼商城期间持续授权驼鱼商城使用。</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";">4、驼鱼商城会对驼鱼商城</span><span style=";">API接口商城业务服务系统</span><span style=";">使用情况进行统计，并可能会与公众或第三方共享这些统计信息，以展示驼鱼商城的整体使用趋势。但这些统计信息不包含您的任何身份识别信息。</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";">5、当驼鱼商城展示您的用户信息时，驼鱼商城会采用包括内容替换、匿名处理方式对您的信息进行脱敏，以保护您的信息安全。</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";">6、当驼鱼商城要将您的用户信息用于本隐私政策未载明的其它用途时，或基于特定目的收集而来的信息用于其他目的时，会通过您主动做出勾选的形式事先征求您的同意。</span>
            </p>
            <h1 style="margin-top:10px;margin-bottom:10px;text-indent:32px;text-align:center">
                <a href="http://undefined"></a><strong><span style=";font-weight:bold;"><span>二、驼鱼商城如何使用</span> Cookie 和同类技术</span></strong><strong></strong>
            </h1>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <strong><span style=";font-weight:bold;">（一）</span></strong><strong><span style=";font-weight:bold;">Cookies</span></strong><strong><span style=";font-weight:bold;">的使用</span></strong><strong></strong>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <strong><span style=";font-weight:bold;">1、为实现您联机体验的个性化需求，使您获得更轻松的访问体验。驼鱼商城会在您的计算机或移动设备上发送一个或多个名为Cookies的小数据文件，指定给您的Cookies 是唯一的，它只能被将Cookies发布给您的域中的Web服务器读取。驼鱼商城向您发送Cookies是为了简化您重复登录的步骤、存储您的</span></strong><strong><span style=";font-weight:bold;">采购</span></strong><strong><span style=";font-weight:bold;">偏好或您购物车中的商品等数据进而为您提供</span></strong><strong><span style=";font-weight:bold;">采购</span></strong><strong><span style=";font-weight:bold;">的偏好设置、帮助判断您的登录状态以及账户或数据安全。</span></strong><strong></strong>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";">2、驼鱼商城不会将 Cookies 用于本隐私政策所述目的之外的任何用途。您可根据自己的偏好管理或删除 Cookies。您可以清除计算机上保存的所有 Cookies，大部分网络浏览器会自动接受Cookies，但您通常可根据自己的需要来修改浏览器的设置以拒绝 Cookies；另外，您也可以清除软件内保存的所有Cookies。但如果您这么做，您可能需要在每一次访问驼鱼商城时亲自更改用户设置，而且您之前所记录的相应信息也均会被删除，并且可能会对您所使用服务的安全性有一定影响。</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";">（二）网</span><span style=";">络</span><span style=";">Beacon</span><span style=";">和同</span><span style=";">类</span><span style=";">技</span><span style=";">术</span><span style=";">的使用</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";"><span>除</span> Cookie 外，驼鱼商城还会在</span><span style=";"><span>驼鱼商城</span>API接口商城业务系统中</span><span style=";"><span>使用网络</span>Beacon等其他同类技术。驼鱼商城</span><span style=";">API接口商城业务系统</span><span style=";"><span>中常会包含一些电子图像（称为</span>&quot;单像素&quot; GIF 文件或 &quot;网络 beacon&quot;）。驼鱼商城使用网络beacon的方式有：</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";">1、通过在驼鱼商城网站上使用网络beacon，计算用户访问数量，并通过访问 cookie 辨认注册的驼鱼商城用户。</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";">2、通过得到的cookies信息，为您提供个性化服务。</span>
            </p>
            <h1 style="margin-top:10px;margin-bottom:10px;text-indent:32px;text-align:center">
                <a href="http://undefined"></a><strong><span style=";font-weight:bold;">三、驼鱼商城如何共享、转让、公开披露您的</span></strong><strong><span style=";font-weight:bold;">企业用户账号</span></strong><strong><span style=";font-weight:bold;">信息</span></strong><strong></strong>
            </h1>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";">（一）共享</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <strong><span style=";font-weight:bold;">1、驼鱼商城不会与企创以外的任何公司、组织和个人共享您的</span></strong><strong><span style=";font-weight:bold;">企业用户账号</span></strong><strong><span style=";font-weight:bold;">信息，但以下情况除外：</span></strong><strong></strong>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <strong><span style=";font-weight:bold;"><span>（</span>1）事先获得您明确的同意或授权；</span></strong><strong></strong>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <strong><span style=";font-weight:bold;"><span>（</span>2）根据适用的法律法规、法律程序的要求、强制性的行政或司法要求所必须的情况下进行提供；</span></strong><strong></strong>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <strong><span style=";font-weight:bold;"><span>（</span>3）在法律法规允许的范围内，为维护驼鱼商城、驼鱼商城的关联方或合作伙伴、您或其他驼鱼商城用户或社会公众利益、财产或安全免遭损害而有必要提供；</span></strong><strong></strong>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <strong><span style=";font-weight:bold;"><span>（</span>4）只有共享您的信息，才能实现驼鱼商城的核心功能或提供您需要的</span></strong><strong><span style=";font-weight:bold;">功能</span></strong><strong><span style=";font-weight:bold;">；</span></strong><strong></strong>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <strong><span style=";font-weight:bold;"><span>（</span>5）应您需求为您处理您与他人的纠纷或争议；</span></strong><strong></strong>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <strong><span style=";font-weight:bold;"><span>（</span>6）符合与您签署的相关协议（包括在线签署的电子协议以及相应的平台规则）或其他的法律文件约定所提供；</span></strong><strong></strong>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <strong><span style=";font-weight:bold;"><span>（</span>7）基于学术研究而使用；</span></strong><strong></strong>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <strong><span style=";font-weight:bold;"><span>（</span>8）基于符合法律法规的社会公共利益而使用。</span></strong><strong></strong>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <strong><span style=";font-weight:bold;">2、驼鱼商城可能会将您的用户信息与驼鱼商城的关联方共享。但驼鱼商城只会共享必要的用户信息，且受本隐私政策中所声明目的的约束。驼鱼商城的关联方如要改变用户信息的处理目的，将再次征求您的授权同意。</span></strong><strong></strong>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <strong><span style=";font-weight:bold;">3、驼鱼商城可能会向合作伙伴等第三方共享您的订单信息、账户信息、设备信息以及位置信息，以保障为您提供的服务顺利完成。但驼鱼商城仅会出于合法、正当、必要、特定、明确的目的共享您的用户信息，并且只会共享提供服务所必要的信息。驼鱼商城的合作伙伴无权将共享的用户信息用于任何其他用途。&nbsp;</span></strong><strong></strong>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";">驼鱼商城的合作伙伴包括以下类型：</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";"><span>（</span>1）商品或技术服务的供应商。驼鱼商城可能会将您的</span><span style=";">企业用户</span><span style=";">信息共享给支持驼鱼商城功能的第三方。这些支持包括为驼鱼商城的供货或提供基础设施技术服务、物流配送服务、支付服务、数据处理等。驼鱼商城共享这些信息的目的是可以实现驼鱼商城</span><span style=";">API接口服务</span><span style=";">功能，比如驼鱼商城必须与物流服务提供商共享您的订单信息才能安排送货；或者驼鱼商城需要将您的订单号和订单金额与第三方支付机构共享以实现其确认您的支付指令并完成支付等。</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";"><span>（</span>2）第三方商家。驼鱼商城必须将您的订单信息与交易有关的必要信息与第三方商家共享来实现您向其</span><span style=";">采购商品</span><span style=";">的需求，并促使其可以完成后续的售后服务。</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";"><span>（</span>3）</span><span style=";">驼鱼商城向您提供对接服务的合作伙伴（包含营销中心事业合作伙伴和联盟商事业合作伙伴）</span><span style=";">。</span><span style=";"><span>为了更好更全面的为您提供驼鱼商城</span>API接口服务及相关营销服务，驼鱼商城将授权合作伙伴向您提供对接服务，以便您更熟练使用驼鱼商城及相关功能。</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <strong><span style=";font-weight:bold;">4、对驼鱼商城与之共享</span></strong><strong><span style=";font-weight:bold;">企业用户账号</span></strong><strong><span style=";font-weight:bold;">信息的公司、组织和个人，驼鱼商城会与其签署严格的保密协定，要求他们按照驼鱼商城的说明、本隐私政策以及其他任何相关的保密和安全措施来处理您的信息。为了保障数据在第三方安全可控，驼鱼商城</span></strong><strong><span style=";font-weight:bold;">采用了第三方云服务</span></strong><strong><span style=";font-weight:bold;">，在云端提供安全可靠的数据使用和存储环境，确保用户数据的安全性。在用户敏感数据使用上，驼鱼商城要求第三方采用数据脱敏和加密技术，从而更好地保护用户数据。</span></strong><strong></strong>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <strong><span style=";font-weight:bold;">5、为了遵守法律、执行或适用驼鱼商城的使用条件和其他协议，或者为了保护驼鱼商城、您或其他驼鱼商城客户的权利及其财产或安全，比如为防止欺诈等违法活动和减少信用风险，而与其他公司和组织交换信息。不过,这并不包括违反本隐私政策中所作的承诺而为获利目的出售、出租、共享或以其它方式披露的您的用户信息。</span></strong><strong></strong>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";">（二）</span><span style=";">转让</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <strong><span style=";font-weight:bold;">驼鱼商城不会将您的用户信息转让给任何公司、组织和个人，但以下情况除外：</span></strong><strong></strong>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <strong><span style=";font-weight:bold;">1、事先获得您明确的同意或授权；</span></strong><strong></strong>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <strong><span style=";font-weight:bold;">2、根据适用的法律法规、法律程序的要求、强制性的行政或司法要求所必须的情况进行提供；</span></strong><strong></strong>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <strong><span style=";font-weight:bold;">3、符合与您签署的相关协议（包括在线签署的电子协议以及相应的平台规则）或其他的法律文件约定所提供；</span></strong><strong></strong>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <strong><span style=";font-weight:bold;">4、在涉及合并、收购、资产转让或类似的交易时，如涉及到</span></strong><strong><span style=";font-weight:bold;">企业用户账号</span></strong><strong><span style=";font-weight:bold;"><span>信息转让，驼鱼商城会要求新的持有您用户信息的公司、组织继续受本隐私政策的约束，否则</span>,驼鱼商城将要求该公司、组织重新向您征求授权同意。&nbsp;&nbsp; &nbsp; &nbsp; &nbsp;</span></strong><strong></strong>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";">（三）公开披露</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <strong><span style=";font-weight:bold;">驼鱼商城仅会在以下情况下，且采取符合业界标准的安全防护措施的前提下，才会公开披露您的用户信息：</span></strong><strong></strong>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <strong><span style=";font-weight:bold;">1、根据您的需求，在您明确同意的披露方式下披露您所指定的用户信息；</span></strong><strong></strong>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <strong><span style=";font-weight:bold;">2、根据法律、法规的要求、强制性的行政执法或司法要求所必须提供您用户信息的情况下，驼鱼商城可能会依据所要求的用户信息类型和披露方式公开披露您的用户信息。在符合法律法规的前提下，当驼鱼商城收到上述披露信息的请求时，驼鱼商城会要求必须出具与之相应的法律文件，如传票或调查函。驼鱼商城坚信，对于要求驼鱼商城提供的信息，应该在法律允许的范围内尽可能保持透明。驼鱼商城对所有的请求都进行了慎重的审查，以确保其具备合法依据，且仅限于执法部门因特定调查目的且有合法权利获取的数据。在法律法规许可的前提下，驼鱼商城披露的文件均在加密密钥的保护之下。</span></strong><strong></strong>
            </p>
            <h1 style="margin-top:10px;margin-bottom:10px;text-indent:32px;text-align:center">
                <a href="http://undefined"></a><strong><span style=";font-weight:bold;">四、驼鱼商城如何保护和保存您的用户信息</span></strong><strong></strong>
            </h1>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";">（一）驼鱼商城保</span><span style=";">护</span><span style=";">您用户信息的技</span><span style=";">术</span><span style=";">与措施</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";">驼鱼商城非常重视用户信息安全，并采取一切合理可行的措施，保护您的用户信息：</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";">1、数据安全技术措施</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";"><span>（</span>1）</span><span style=";"><span>驼鱼商城会采用符合业界标准的安全防护措施，包括建立合理的制度规范、安全技术来防止您的用户信息遭到未经授权的访问使用、修改</span>,避免数据的损坏或丢失。</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";"><span>（</span>2）</span><span style=";"><span>驼鱼商城的网络服务采取了传输层安全协议等加密技术，通过</span>https等方式提供浏览服务，确保用户数据在传输过程中的安全。</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";"><span>（</span>3）</span><span style=";">驼鱼商城采取加密技术对用户用户信息进行加密保存，并通过隔离技术进行隔离。</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";"><span>（</span>4）</span><span style=";"><span>在用户信息使用时，例如用户信息展示、用户信息关联计算，驼鱼商城会采用包括内容替换、</span>SHA256在内多种数据脱敏技术增强用户信息在使用中安全性。</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";"><span>（</span>5）</span><span style=";">驼鱼商城采用严格的数据访问权限控制和多重身份认证技术保护用户信息，避免数据被违规使用。</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";"><span>（</span>6）</span><span style=";">驼鱼商城采用代码安全自动检查、数据访问日志分析技术进行用户信息安全审计。</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";">2、驼鱼商城为保护用户信息采取的其他安全措施</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";"><span>（</span>1）</span><span style=";">驼鱼商城通过建立数据分类分级制度、数据安全管理规范、数据安全开发规范来管理规范用户信息的存储和使用。</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";"><span>（</span>2）</span><span style=";">驼鱼商城通过信息接触者保密协议、监控和审计机制来对数据进行全面安全控制。</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";"><span>（</span>3）</span><span style=";">驼鱼商城建立数据安全委员会并下设信息保护专职部门、数据安全应急响应组织来推进和保障用户信息安全。</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";"><span>（</span>4）</span><span style=";"><span>安全认证和服务。驼鱼商城存储您的用户数据的底层云技术取得了数据中心联盟颁发的</span>“可信云”认证三级认证、通过了公安部安全等级保护三级认证，同时还获得了ISO27000认证。</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";"><span>（</span>5）</span><span style=";">加强安全意识。企创还会举办安全和隐私保护培训课程，加强员工对于保护用户信息重要性的认识。</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";">3、企创仅允许有必要知晓这些信息的驼鱼商城及驼鱼商城关联方的员工、合作伙伴访问用户信息，并为此设置了严格的访问权限控制和监控机制。驼鱼商城同时要求可能接触到您的用户信息的所有人员履行相应的保密义务。如果未能履行这些义务，可能会被追究法律责任或被中止与驼鱼商城的合作关系。</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";">4、驼鱼商城会采取一切合理可行的措施，确保未收集无关的用户信息。驼鱼商城只会在达成本隐私政策所述目的所需的期限内保留您的用户信息，除非需要延长保留期或受到法律的允许。</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";">5、</span><span style=";">驼鱼商城</span><span style=";">大力强化对驼鱼商城</span><span style=";">仓储</span><span style=";">员工的数据安全培训和要求，提高</span><span style=";">仓储</span><span style=";">员工保护用户隐私数据的意识。</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";">6、互联网并非绝对安全的环境，而且电子邮件、即时通讯、社交软件等与其他用户的交流方式无法确定是否完全加密，驼鱼商城建议您使用此类工具时请使用复杂密码，并注意保护您的用户信息安全。</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";">7、互联网环境并非百分之百安全，驼鱼商城将尽力确保或担保您发送给驼鱼商城的任何信息的安全性。如果驼鱼商城的物理、技术、或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改、或毁坏，导致您的合法权益受损，驼鱼商城将承担相应的法律责任。</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";">8、安全事件处置</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";">在通过驼鱼商城网站与第三方进行网上商品或服务的交易时，您不可避免的要向交易对方或潜在的交易对方披露自己的用户信息，如联络方式或者邮政地址等。请您妥善保护自己的企业用户信息，仅在必要的情形下向他人提供。</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";">为应对用户信息泄露、损毁和丢失等可能出现的风险，驼鱼商城制定了多项制度，明确安全事件、安全漏洞的分类分级标准及相应的处理流程。驼鱼商城也为安全事件建立了专门的应急响应团队，按照安全事件处置规范要求，针对不同安全事件启动安全预案，进行止损、分析、定位、制定补救措施、联合相关部门进行溯源和打击。</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";">在不幸发生用户信息安全事件后，驼鱼商城将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、驼鱼商城已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。驼鱼商城同时将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知用户信息主体时，驼鱼商城会采取合理、有效的方式发布公告。同时，驼鱼商城还将按照监管部门要求，主动上报个用户信息安全事件的处置情况。</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <strong><span style=";font-weight:bold;">9、如果您对驼鱼商城的用户信息保护有任何疑问，可通过本隐私政策最下方约定的联系方式联系驼鱼商城。如您发现自己的用户信息泄密，尤其是您的账户及密码发生泄露，请您立即通过本隐私政策最下方约定的联系方式联络驼鱼商城，以便驼鱼商城采取相应措施。&nbsp;</span></strong><strong></strong>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";">（二）您的用户信息的保存</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";">1、您的用户信息将全被存储于中华人民共和国境内。如您使用跨境交易服务，且需要向境外传输您的用户信息完成交易的，驼鱼商城会单独征得您的授权同意并要求接收方按照驼鱼商城的说明、本隐私政策以及其他任何相关的保密和安全措施来处理这些用户信息。</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";">2、</span><strong><span style=";font-weight:bold;">除非法律法规另有规定，您的用户信息驼鱼商城将保存至您账号注销之日后的一个月。</span></strong><span style=";">驼鱼商城承诺这是为了保证您在驼鱼商城</span><span style=";">API接口服务</span><span style=";">的消费者权益，您的用户信息在驼鱼商城须保存的最短期间。当您的用户信息超出驼鱼商城所保存的期限后，驼鱼商城会对您的用户信息进行删除或匿名化处理。</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";">3</span><span style=";">、</span><strong><span style=";font-weight:bold;">如果驼鱼商城终止服务或运营，驼鱼商城会至少提前三十日向您通知，并在终止服务或运营后对您的用户信息进行删除或匿名化处理。</span></strong>
            </p>
            <h1 style="margin-top:10px;margin-bottom:10px;text-align:center">
                <a href="http://undefined"></a><strong><span style=";font-weight:bold;">五、您如何管理您的用户信息</span></strong><strong></strong>
            </h1>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";">驼鱼商城非常重视您对用户信息的关注，并尽全力保护您对于您的用户信息访问、更正、删除以及撤回同意的权利，以使您拥有充分的能力保障您的隐私和安全。您的权利包括：</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";">1</span><span style=";">、</span><span style=";">访问</span><span style=";">和更正您的用户信息</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";">除法律法规规定外，您有权随时访问和更正您的用户信息，具体包括：</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";;"><span>（</span>1）您的账户信息：PC端您可以在“我的驼鱼商城”页面的相应的菜单中查阅您提交给驼鱼商城的所有用户信息，你也可通过上述途径更新除实名认证信息之外的其他用户信息（您的实名认证信息是您通过实名认证时使用的姓名和身份证信息），如您需要变更您的实名认证信息，您可拨打</span><span style=";;">本隐私政策下方联系方式</span><span style=";"><span>申请变更。页面展示会以更新后的信息为准。</span> </span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";">（</span><span style=";">2</span><span style=";">）对于您在使用驼鱼商城过程中产生的其他用户信息需要访问或更正，请随时联系驼鱼商城。驼鱼商城会根据本隐私政策所列明的方式和期限响应您的请求。</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";">（</span><span style=";">3</span><span style=";">）您无法访问和更正的用户信息：除上述列明的信息外，您的部分用户信息驼鱼商城还无法为您提供访问和更正的服务，这些信息主要是为了提升您的用户体验和保证交易安全所收集的您的设备信息、您使用附加功能时产生的用户信息。上述信息驼鱼商城会在您的授权范围内进行使用，您无法访问和更正，但您可联系驼鱼商城进行删除或做匿名化处理。</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";">2</span><span style=";">、</span><span style=";">删</span><span style=";">除您的用户信息</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";">您在驼鱼商城页面中可以直接清除或删除的信息，包括订单信息、浏览信息、收货地址信息；</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";">在以下情形中，您可以向驼鱼商城提出删除用户信息的请求：</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";"><span>（</span>1）如果驼鱼商城处理用户信息的行为违反法律法规；</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";"><span>（</span>2）如果驼鱼商城收集、使用您的用户信息，却未征得您的同意；</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";"><span>（</span>3）如果驼鱼商城处理用户信息的行为违反了与您的约定；</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";"><span>（</span>4）如果您注销了驼鱼商城账户；</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";"><span>（</span>5）如果驼鱼商城终止服务及运营。</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";">若驼鱼商城决定响应您的删除请求，驼鱼商城还将同时通知从驼鱼商城获得您的用户信息的实体，要求其及时删除，除非法律法规另有规定，或这些实体获得您的独立授权。当您从驼鱼商城的服务中删除信息后，驼鱼商城可能不会立即备份系统中删除相应的信息，但会在备份更新时删除这些信息。</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";">3</span><span style=";">、改</span><span style=";">变</span><span style=";">您授</span><span style=";">权</span><span style=";">同意的范</span><span style=";">围</span><span style=";">或撤回您的授</span><span style=";">权</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";">您可以通过删除信息、关闭设备功能、在驼鱼商城网站或软件中进行隐私设置等方式改变您授权驼鱼商城继续收集用户信息的范围或撤回您的授权。您也可以通过注销账户的方式，撤回驼鱼商城继续收集您用户信息的全部授权。</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";">请您理解，每个业务功能需要一些基本的用户信息才能得以完成，当您撤回同意或授权后，驼鱼商城无法继续为您提供撤回同意或授权所对应的服务，也不再处理您相应的用户信息。但您撤回同意或授权的决定，不会影响此前基于您的授权而开展的用户信息处理。</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";">4</span><span style=";">、注</span><span style=";">销账户</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";">您可以</span><span style=";">向</span><span style=";">驼鱼商城的产品中直接申请注销账户。您注销账户后，除法律法规另有规定外，驼鱼商城将停止为您提供服务，并依据您的要求删除您的用户信息。</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";">5</span><span style=";">、响</span><span style=";">应</span><span style=";">您的</span><span style=";">请</span><span style=";">求</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";">如果您无法通过上述方式访问、更正或删除您的用户信息，或您需要访问、更正或删除您在使用驼鱼商城时所产生的其他用户信息，或您认为驼鱼商城存在任何违反法律法规或与您关于用户信息的收集或使用的约定，您均可以通过</span><span style=";">本隐私政策</span><span style=";">下方的</span><span style=";">联系</span><span style=";"><span>方式与驼鱼商城联系。为了保障安全，驼鱼商城可能需要您提供书面请求，或以其他方式证明您的身份，驼鱼商城将在收到您反馈并验证您的身份后的</span>30天内答复您的请求。对于您合理的请求，驼鱼商城原则上不收取费用，但对多次重复、超出合理限度的请求，驼鱼商城将视情收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，驼鱼商城可能会予以拒绝。</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <strong><span style=";font-weight:bold;">在以下情形中，按照法律法规要求，驼鱼商城将无法响应您的请求：</span></strong><strong></strong>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <strong><span style=";font-weight:bold;"><span>（</span>1）与国家安全、国防安全有关的；</span></strong><strong></strong>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <strong><span style=";font-weight:bold;"><span>（</span>2）与公共安全、公共卫生、重大公共利益有关的；</span></strong><strong></strong>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <strong><span style=";font-weight:bold;"><span>（</span>3）与犯罪侦查、起诉和审判等有关的；</span></strong><strong></strong>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <strong><span style=";font-weight:bold;"><span>（</span>4）有充分证据表明您存在主观恶意或滥用权利的；</span></strong><strong></strong>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <strong><span style=";font-weight:bold;"><span>（</span>5）响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的。</span></strong><strong></strong>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <strong><span style=";font-weight:bold;">&nbsp;</span></strong>
            </p>
            <h1 style="margin-top:10px;margin-bottom:10px;text-indent:32px;text-align:center">
                <a href="http://undefined"></a><strong><span style=";font-weight:bold;">六、通知和修订</span></strong><strong></strong>
            </h1>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";">1、为给您提供更好的服务以及随着驼鱼商城业务的发展，本隐私政策也会随之更新。但未经您明确同意，驼鱼商城不会削减您依据本隐私政策所应享有的权利。驼鱼商城会通过在驼鱼商城网站、驼鱼商城移动端或驼鱼商城小程序上发出更新版本并在生效前通过网站公告或以其他适当方式提醒您相关内容的更新，也请您访问驼鱼商城以便及时了解最新的隐私政策。</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";">2、对于重大变更，驼鱼商城还会提供更为显著的通知（驼鱼商城会通过包括但不限于邮件、短信或在浏览页面做特别提示等方式，说明隐私政策的具体变更内容）。</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";">本隐私政策所指的重大变更包括但不限于：</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";"><span>（</span>1）驼鱼商城的服务模式发生重大变化。如处理用户信息的目的、处理的用户信息类型、用户信息的使用方式等；</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";"><span>（</span>2）驼鱼商城在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";"><span>（</span>3）用户信息共享、转让或公开披露的主要对象发生变化；</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";"><span>（</span>4）您参与用户信息处理方面的权利及其行使方式发生重大变化；</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";"><span>（</span>5）驼鱼商城负责处理用户信息安全的责任部门、联络方式及投诉渠道发生变化时；</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";"><span>（</span>6）用户信息安全影响评估报告表明存在高风险时。</span>
            </p>
            <h1 style="margin-top:10px;margin-bottom:10px;text-indent:32px;text-align:center">
                <a href="http://undefined"></a><strong><span style=";font-weight:bold;">七、如何联系驼鱼商城</span></strong><strong></strong>
            </h1>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";">1、如您对本隐私政策或您用户信息的相关事宜有任何问题、意见或建议，请通过拨打驼鱼商城的任何一部客服电话</span><span style=";">（</span><span style=";">400</span><span style=";">-</span><span style=";">8899367</span><span style=";">）</span><span style=";">与驼鱼商城联系。</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";">2、驼鱼商城设立了用户信息保护专职部门（或用户信息保护专员），您可以通过</span><span style=";">拨打</span><span style=";">400</span><span style=";">-</span><span style=";">8899367与其联系。</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";">3、一般情况下，驼鱼商城将在十天内回复。如果您对驼鱼商城的回复不满意，特别是驼鱼商城的用户信息处理行为损害了您的合法权益，您还可以向网信、电信、公安及工商等监管部门进行投诉或举报。</span>
            </p>
            <p style="margin-top:10px;margin-bottom:10px;text-indent:32px">
                <span style=";">本《</span><span style=";">济南企创信息技术咨询服务有限公司</span><span style=";">隐私政策》发布之日起生效</span><span style=";">。</span>
            </p>

        </div>
    </div>
</template>
<script>
    export default {

    }
</script>
<style>
    .tab_title {
        margin: 0 50px;
        padding: 54px 0 26px;
        border-bottom: 1px solid #F7F5F5;
        font-size: 20px;
        text-align: left;
    }
    .tab-text,
    .tab-contact {
        margin: 30px 50px;
        height: 648px;
        overflow: auto;
    }

    .tab-text h2 {
        text-align: center;
        font-size: 20px;
        margin-bottom: 20px;
    }

    .tab-text p {
        font-size: 14px;
        line-height: 30px;
    }

</style>
