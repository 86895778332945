<template>
    <div>
        <div class="tab_title">关于我们</div>
        <div class="tab-text">

            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;济南企创信息技术咨询服务有限公司，成立于2019年，公司位于山东济南。公司致力于为中国中小微企业提供一站式的供应链解决方案，帮助拥有自营平台的企业一站式对接海量低价货源，并提供从选货、发货到售后的一站式服务，帮助平台快速建立成熟的供应链服务体系。</p>
            <p>我们的服务对象包括：</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;①自有平台，需要扩充更多更低价优质商品的电商平台或连锁企业。</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;②商会、协会等企业组织或个人组织，想通过建立自有平台加强会员之间粘性，并为会员提供更好更优质服务的组织。</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;③培训机构、培训学校，需要通过建立平台为现有学员提供更多交流、交易的平台。</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;④抖商、微商等希望通过自有平台建立私域化流量的个人商家用户。</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;⑤其他B2B、B2C平台</p>

        </div>
    </div>
</template>
<script>
    export default {

    }
</script>
<style>
    .tab_title {
        margin: 0 50px;
        padding: 54px 0 26px;
        border-bottom: 1px solid #F7F5F5;
        font-size: 20px;
        text-align: left;
    }
    .tab-text,
    .tab-contact {
        margin: 30px 50px;
        height: 648px;
        overflow: auto;
    }

    .tab-text h2 {
        text-align: center;
        font-size: 20px;
        margin-bottom: 20px;
    }

    .tab-text p {
        text-align: left;
        font-size: 14px;
        line-height: 30px;
    }

</style>
