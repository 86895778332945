<template>
    <div>
        <div class="tab_title">免责声明</div>
        <div class="tab-text">

            <p style="line-height:27px"> <span>尊敬的用户：</span> </p>
            <p style="text-indent:32px;line-height:27px"> <span>您即将通过本页面确认使用济南企创信息技术咨询服务有限公司（以下简称</span><span>“</span><span>企创</span><span>”</span><span>）运营的</span><span>“</span><span><span>驼鱼商城项目，包括但不限于驼鱼商城</span>APP、PC端、小程序、公众号</span><span>”</span><span>（以下简称</span><span>“</span><span>驼鱼商城</span><span>”</span><span>），您在使用驼鱼商城之前，请您仔细阅读本免责声明的全部内容</span><strong><span style="font-weight:bold;">（尤其是加粗及标下划线内容）</span></strong><span>。如果您对本免责声明的条款有疑问的，请您暂停后续操作并向企创客服进行询问，企创客服将向您解释条款内容。</span><strong><span style="text-decoration:underline;"><span style="font-weight:bold;text-underline:single;">如果您不同意本免责声明的任意条款内容，或者无法准确理解本免责声明条款内容及企创客服的解释，请您停止后续操作。</span></span></strong><strong></strong> </p>
            <p style="text-indent:32px;line-height:27px"> <span>一旦您通过本系统页面在线点击确认或以其他方式选择接受本免责声明的，即意味着您自愿同意接受本免责声明的条款内容。本免责声明可由企创根据需求更新，更新后的相关条款公布于驼鱼商城平台，您可在本系统中查阅最新版免责声明条款内容。</span><strong><span style="text-decoration:underline;"><span style="font-weight:bold;text-underline:single;">如果您不同意更新后的免责声明的任意条款内容，或者无法准确理解更新后的免责声明条款内容及企创客服的解释，请您停止继续使用驼鱼商城。</span></span></strong><strong></strong> </p>
            <p style="text-indent:32px;line-height:27px"> <strong><span style="font-weight:bold;">免责声明条款如下：</span></strong><strong></strong> </p>
            <p style="text-indent:32px;line-height:27px"> <span>1</span><span>、</span><strong><span style="font-weight:bold;">您有义务在注册时提供</span></strong><strong><span style="font-weight:bold;">真实的基本资料</span></strong><span>，并保证包括但不限于您的登录账号名、公司名称、联系方式、营业执照及联系电话号码等基本信息的有效性及安全性，保证企创可以通过上述联系方式与您取得联系。同时，</span><strong><span style="font-weight:bold;">您有义务在相关资料实际变更时及时更新有关注册资料。</span></strong> </p>
            <p style="text-indent:32px;line-height:27px"> <span>2</span><span>、</span><strong><span style="text-decoration:underline;"><span style="font-weight:bold;text-underline:single;">您使用驼鱼商城过程中所进行的任何操作</span></span></strong><span>，包括但不限于采购、交易以及其他使用驼鱼商城所产生的相关内容，</span><strong><span style="text-decoration:underline;"><span style="font-weight:bold;text-underline:single;">不得违反国家法律、法规、规范性文件及驼鱼商城公示的相关规则、制度</span></span></strong><strong><span style="font-weight:bold;">。</span></strong> </p>
            <p style="text-indent:32px;line-height:27px"> <span>3</span><span>、</span><strong><span style="text-decoration:underline;"><span style="font-weight:bold;text-underline:single;">您不得利用驼鱼商城从事任何侵害企创、驼鱼商城、其他第三方合法权益的行为。</span></span></strong><strong><span style="text-decoration:underline;"></span></strong> </p>
            <p style="text-indent:32px;line-height:27px"> <span>4</span><span>、</span><strong><span style="text-decoration:underline;"><span style="font-weight:bold;text-underline:single;">您不得对驼鱼商城采取任何反向工程手段进行破解，不得对驼鱼商城（包括但不限于源程序、目标程序、技术文档、客户端至服务器端的数据、服务器数据等）进行复制、修改、编译、整合或篡改，不得修改或增删驼鱼商城的任何功能。</span></span></strong><span style="text-decoration:underline;"></span> </p>
            <p style="text-indent:32px;line-height:27px"> <span>5</span><span>、</span><strong><span style="font-weight:bold;">尊重驼鱼商城的知识产权是您应尽的义务。非经企创书面同意，您不得擅自使用、修改、反向编译、复制、公开传播、改变、散布、发行或公开发表驼鱼商城所有内容</span></strong><span>（包括但不限于企业商标、图片、宣传、著作、档案、资讯、资料、网站架构、网站画面的安排、网页设计及排版等）。</span> </p>
            <p style="text-indent:32px;line-height:27px"> <span>6</span><span>、</span><strong><span style="text-decoration:underline;"><span style="font-weight:bold;text-underline:single;">您完全理解并知晓网络服务的特殊性，若出现以下任一情况致使驼鱼商城无法及时为您提供服务的，企创不承担任何违约或赔偿责任</span></span></strong><strong><span style="font-weight:bold;">，</span></strong><span>但企创会尽合理努力积极促使驼鱼商城恢复工作：</span> </p>
            <p style="text-indent:32px;line-height:27px"> <span>（</span><strong><span style="font-weight:bold;">1</span></strong><strong><span style="font-weight:bold;">）与驼鱼商城相关的服务端设备停机维护或升级期间；</span></strong><strong></strong> </p>
            <p style="text-indent:32px;line-height:27px"> <strong><span style="font-weight:bold;">（</span></strong><strong><span style="font-weight:bold;">2</span></strong><strong><span style="font-weight:bold;">）因台风、地震、洪水、雷电或恐怖袭击等不可抗力原因；</span></strong><strong></strong> </p>
            <p style="text-indent:32px;line-height:27px"> <strong><span style="font-weight:bold;">（</span></strong><strong><span style="font-weight:bold;">3</span></strong><strong><span style="font-weight:bold;">）您使用的电脑、手机及其他计算机软硬件和通信线路、供电线路出现故障的；</span></strong><strong></strong> </p>
            <p style="text-indent:32px;line-height:27px"> <strong><span style="font-weight:bold;">（</span></strong><strong><span style="font-weight:bold;">4</span></strong><strong><span style="font-weight:bold;">）您操作不当或通过非企创授权或认可的方式使用驼鱼商城的；</span></strong><strong></strong> </p>
            <p style="text-indent:32px;line-height:27px"> <strong><span style="font-weight:bold;">（</span></strong><strong><span style="font-weight:bold;">5</span></strong><strong><span style="font-weight:bold;">）因病毒、木马、恶意程序攻击、网络拥堵、系统不稳定、系统或设备故障、通讯故障、电力故障、银行原因、第三方服务瑕疵或政府行为等原因</span></strong><strong><span style="font-weight:bold;">；</span></strong><strong></strong> </p>
            <p style="text-indent:32px;line-height:27px"> <strong><span style="font-weight:bold;">（</span></strong><strong><span style="font-weight:bold;">6</span></strong><strong><span style="font-weight:bold;">）法律、法规及企创与您签署的其他协议文件约定的情形。</span></strong><strong></strong> </p>
            <p style="text-indent:32px;line-height:27px"> <span>7</span><span>、</span><strong><span style="font-weight:bold;">本免责声明因国家相关主管部门颁布、变更的法律、法规、规章、规定、指引、通知、政策或其他规范性文件及行业指导文件等原因，导致企创不能提供约定服务的，不应视为违约</span></strong><span>，企创可根据相关规定变更本免责声明内容，但企创会尽合理努力保障您的合法权益。</span> </p>
            <p style="text-indent:32px;line-height:27px"> <span>8</span><span>、</span><strong><span style="font-weight:bold;">您理解并知晓驼鱼商城有赖于服务商的准确运行及操作，若出现系统差错、故障或其他原因引发的错误，导致您不当得利等情形，您同意企创或驼鱼商城采取更正差错、扣划款项等适当纠正措施。</span></strong> </p>
            <p style="text-indent:32px;line-height:27px"> <span>9</span><span>、</span><strong><span style="text-decoration:underline;"><span style="font-weight:bold;text-underline:single;">您若违反本免责声明中相关约定的，因此所产生的所有法律责任均由您承担，企创有权在保留必要的证据资料后，自行或根据有关机关的要求暂停或注销您的账号，并保留追究您法律责任的权利。</span></span></strong><strong><span style="text-decoration:underline;"></span></strong> </p>
            <p style="text-indent:32px;line-height:27px"> <span>10、本声明自公布之日起生效。</span> </p>
            <p style="text-indent:32px;line-height:27px"> <span>&nbsp;</span> </p>
            <p style="text-indent:32px;line-height:27px"> <span>&nbsp;</span> </p>
            <p style="text-indent:32px;line-height:27px"> <span>&nbsp;</span> </p>
            <p style="text-indent:32px;line-height:27px"> <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span>2019 年</span> <span> 10 月</span> <span> 08 日</span></span> </p>
            <p style="text-indent:32px;line-height:27px"> <span>&nbsp;</span> </p>
            <p style="text-indent:32px;line-height:27px"> <span>&nbsp;</span> </p>

        </div>
    </div>
</template>
<script>
    export default {

    }
</script>
<style>
    .tab_title {
        margin: 0 50px;
        padding: 54px 0 26px;
        border-bottom: 1px solid #F7F5F5;
        font-size: 20px;
        text-align: left;
    }
    .tab-text,
    .tab-contact {
        margin: 30px 50px;
        height: 648px;
        overflow: auto;
    }

    .tab-text h2 {
        text-align: center;
        font-size: 20px;
        margin-bottom: 20px;
    }

    .tab-text p {
        font-size: 14px;
        line-height: 30px;
    }

</style>
