<template>
    <div>
        <div class="tab_title">联系我们</div>
        <div class="tab-contact">
            <icon name="icondianhua" size="70px" color="#E60026" class="btn"></icon>
            <div>
                <p>全国服务热线</p>
                <p class="red">4008899367</p>
                <p>周一至周日 09:00-19:00</p>
            </div>
        </div>
    </div>
</template>
<script>
    import icon from '@/components/icon/iconfont.vue'
    export default {
        components:{
            icon
        },
    }
</script>
<style>
    .tab_title {
        margin: 0 50px;
        padding: 54px 0 26px;
        border-bottom: 1px solid #F7F5F5;
        font-size: 20px;
        text-align: left;
    }
    .tab-text,
    .tab-contact {
        margin: 30px 50px;
        height: 648px;
        overflow: auto;
    }

    .tab-text h2 {
        text-align: center;
        font-size: 20px;
        margin-bottom: 20px;
    }

    .tab-text p {
        font-size: 14px;
        line-height: 30px;
    }

</style>
