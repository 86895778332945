<template>
    <div>
        <div class="tab_title">意见反馈</div>

        <div class="textarea-wrap">
            <textarea name="content" id="textarea-box" maxlength="200" v-model="textCon" ></textarea>
<!--            <div id="contentwordage"></div>-->
        </div>
        <div v-if="isShowBtn" class="red-btn" @click="goSubmit">提交</div>
        <div v-if="!isShowBtn"  class="red-btn"@click="goLogin" >请先登录</div>
        <!-- 提示 -->
        <toast ref="toast"></toast>
    </div>
</template>
<script>
    import icon from '@/components/icon/iconfont.vue'
    import toast from "@/components/toast.vue"
    import {mySuggets} from '@/views/aboutUs/apiAbout' // 留言接口地址
    export default {
        components:{
            icon,
            toast
        },
        data(){
            return{
                isShowBtn:true,
                textCon:'',
                ip:''
            }
        },
        mounted(){
            let cookie = JSON.parse(localStorage.getItem('cookie') || '{}')

            // 判断是否登录
            if(cookie.tokenId ){
                // 是否显示成本价
                this.isShowBtn = true
            }else {
                this.isShowBtn = false

            }
        },
        methods:{

            async goSubmit() {
                let { message, code, data, status } = await mySuggets({
                    content:this.textCon,
                    ip:localStorage.getItem('Ip')
                });
                if (status == 200) {
                    this.$refs.toast.showToast("留言成功~~");

                }
            },
            goLogin(){
                this.$refs.toast.showToast("请先去登录~~");
            }
        }
    }
</script>
<style>
    .tab_title {
        margin: 0 50px;
        padding: 54px 0 26px;
        border-bottom: 1px solid #F7F5F5;
        font-size: 20px;
        text-align: left;
    }
    .tab-text,
    .tab-contact {
        margin: 30px 50px;
        height: 648px;
        overflow: auto;
    }

    .tab-text h2 {
        text-align: center;
        font-size: 20px;
        margin-bottom: 20px;
    }

    .tab-text p {
        font-size: 14px;
        line-height: 30px;
    }

</style>
