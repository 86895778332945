<template>
    <div>
        <div class="header">
            <div class="headcon">
                <span  @click="goHomeIndex"><icon name="iconshouye" size="15px" color="#ddd" class="btn"></icon>&nbsp;驼鱼商城首页</span>
                <button @click="loginOut"  v-if="loginStatus">退出</button>
                <p v-if="loginStatus">欢迎您 <b style="color: red">{{userName}}</b></p>
                <p @click="loginPop" v-if="!loginStatus">你好，请登录</p>
            </div>
        </div>

        <!-- 提示 -->
        <toast ref="toast"></toast>

        <!--begin关于我们-->

        <div class="about_box_big">
            <div class="about_box">
                <ul class="left-tab fl">
                    <li v-for="(item ,index) in tabList"
                        class="tab"
                        :class="{active:active == index}"
                        @click="toggle(index)"
                    >{{item}}</li>
                </ul>
                <div class="right-content fl">
                    <component :is="currentView" class="tab-main"></component>
                </div>
            </div>
        </div>
        <!--end关于我们-->
        <!--   底部    -->
        <myFooter></myFooter>

        <!--        登录弹框 begin -->
        <div class="login_bg" v-if="isShowPop">
            <div class="login_box">
                <ul>
                    <li>
                        <icon name="iconwodedangxuan" size="25px" color="#ddd" class="btn"></icon>
                        <input type="text" placeholder="输入用户名" value="" v-model="loginInfo.user_name"/>
                    </li>
                    <li>
                        <icon name="iconlock" size="25px" color="#ddd" class="btn"></icon>
                        <input type="password" placeholder="输入密码" value="" v-model="loginInfo.password"/>
                    </li>
                </ul>
                <button class="fl" @click="loginCancle">取消</button>
                <button class="fr red" @click="login">确定</button>
            </div>
        </div>
        <!-- 登录弹框  end -->
        <router-view></router-view>

    </div>

</template>

<script>
    import icon from '@/components/icon/iconfont'
    import toast from "@/components/toast"
    import footer from "@/components/footer" // 底部
    import aboutUs from '@/views/aboutUs/aboutUs' // 关于我们
    import suggestion from '@/views/aboutUs/suggestion' // 意见反馈
    import contactUs from '@/views/aboutUs/contactUs' // 联系我们
    import disclaimer from '@/views/aboutUs/disclaimer' // 免责声明
    import privacyPolicy from '@/views/aboutUs/privacyPolicy' // 隐私政策
    import {login} from '@/views/homePage/home' // 获取登录

    export default {
        components:{
            icon,
            toast,
            aboutUs,
            suggestion,
            contactUs,
            disclaimer,
            privacyPolicy,
            myFooter:footer
        },
        data(){
            return{
                userName:'',
                loginStatus:false, // 是否登录
                isShowPop:false, // 是否显示登录弹框
                loginMsg:'', // 登录返回错误信息提示
                // 登录
                loginInfo:{
                    user_name:'', // 用户名
                    password:'' // 密码
                },
                active: 0,
                currentView: "aboutUs",
                tabList: ["关于我们","意见反馈","联系我们","免责声明","隐私政策"]

            }

        },

        mounted(){
            let cookie = JSON.parse(localStorage.getItem('cookie') || '{}')
            this.userName = cookie.user_name;
            // 判断是否登录
            if(cookie.tokenId ){
                this.loginStatus = true
            }
            //  切换tab
            this.toggle(this.$route.query.tab)


        },
        methods:{
            //跳转首页
            goHomeIndex(){
                this.$router.push({path:'/index'})
            },
            // 登录接口
            async login() {
                // 防止多次提交
                // if (this.isClick) return;
                let params = this.loginInfo;
                if (params.user_name == "") {
                    this.$refs.toast.showToast("请输入账号~~");
                } else if (params.password == "") {
                    this.$refs.toast.showToast("请输入密码~~");
                } else {
                    let { message, code, data, status } = await login(params);
                    if (status == 200) {
                        // 设置cookie
                        let cookie = localStorage.getItem("cookie") || {};

                        cookie = JSON.parse(decodeURIComponent(decodeURIComponent(data.psw)));
                        localStorage.setItem("cookie", JSON.stringify(cookie));
                        this.loginInfo.user_name = ''
                        this.loginInfo.password = ''
                        // 登录状态改为true
                        this.$refs.toast.showToast("登录成功");
                        // 隐藏弹框
                        this.isShowPop = false;
                        location.reload();
                    } else {
                        this.$refs.toast.showToast(message);
                    }
                }

            },
            // 退出登录
            loginOut(){
                // 清除本地存储
                localStorage.clear();
                // 登录状态改为false
                this.loginStatus =  false

                this.$refs.toast.showToast("退出成功");
                location.reload();
            },
            // 是否显示弹框
            loginCancle(){
                this.isShowPop = false;
            },
            loginPop(){
                this.isShowPop = true;

            },

            // 判断显示那个路由
            toggle: function(index) {
                this.active = index;
                if (index == 0) {
                    this.currentView = "aboutUs";
                } else if (index == 1) {
                    this.currentView = "suggestion";
                } else if (index == 2) {
                    this.currentView = "contactUs";
                } else if (index == 3) {
                    this.currentView = "disclaimer";
                } else if (index == 4) {
                    this.currentView = "privacyPolicy";
                }
            }
        }

    }
</script>
<style lang="less" scoped>

    @width:1200px;
    .search_box{
        width: @width;
        height: 206px;
        margin: 0 auto;
        span{
            float: left;
            height: 160px;
        }
        img{
            width: 227px;
            height: 85px;
            margin-top: 30px;
            float: left;
        }
        .searchcon{
            float: left;
            width: 742px;
            height: 85px;
            margin-top: 30px;
            margin-left: 80px;
            padding-top: 30px;
            div{
                position: relative;
                width: 742px;
                height: 36px;
                border: 2px solid #EEEEEE;
            }
            input{
                width: 662px;
                height: 36px;
                line-height: 36px;
                float: left;
                padding-left: 10px;
            }
            button{
                width: 70px;
                height: 38px;
                line-height: 38px;
                color: #fff;
                background: #EC1430;
                border: none;
                position: absolute;
                top: -1px;
                right: -1px;
                outline: none;
                cursor: pointer;
            }
            ul{
                width: 742px;
                height: 22px;
                line-height: 22px;
                padding-top: 10px;
                display: block;
                li{
                    float: left;
                    color: #999999;
                    font-size: 14px;
                    padding-right: 10px;
                    cursor: pointer;
                }
                li:hover,li.active{
                    color:#EC1430
                }
                li:first-child{
                    color:#EC1430
                }

            }

        }
        dl{
            float: left;
            width: 1200px;
            height: 44px;
            dd{
                width: 148px;
                height: 44px;
                line-height: 44px;
                float: left;
                color: #333333;
                font-size: 16px;
                cursor: pointer;
            }
            dd.active,dd:hover{
                color: #fff;
                background: url("../../assets/images/bg_all_Classification.png") no-repeat center center;
            }
        }
    }
    /*   登录  */
    .login_bg{
        width: 100%;
        height:100%;
        background: rgba(0,0,0,.6);
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        z-index: 111;
        .login_box{
            width: 490px;
            height: 340px;
            background: #fff;
            position: fixed;
            top: 50%;
            left: 50%;
            margin-left: -245px;
            margin-top: -170px;
            border-radius:10px;
            button{
                width:180px;
                height:60px;
                border:1px solid rgba(203,203,203,1);
                border-radius:4px;
                color: #CBCBCB;
                font-size: 20px;
                background: none;
                margin-top: 40px;
                margin-left: 49px;
                cursor: pointer;
                outline: none;
            }
            button.red{
                background: #E60026;
                color: #fff !important;
                margin-right: 49px;
                margin-left: 0;
                border:1px solid #E60026;
            }
            .showtip{
                height: 32px;
                line-height: 32px;
                padding: 0 20px;
                position: absolute;
                top: 50%;
                left:50%;
                transform: translate(-50%, -50%);
                background: rgba(0,0,0,.5);
                color: #fff;
                border-radius: 4px;
            }
            ul{
                width: 390px;
                margin: 0 auto;
                padding-top: 50px;
                li{
                    width: 388px;
                    height: 58px;
                    border: 1px solid #ddd;
                    border-radius: 4px;
                    margin-bottom: 20px;
                    float: left;
                    .btn{
                        float: left;
                        width: 58px;
                        height: 58px;
                        padding-right: 7px;
                        text-align: center;
                        line-height: 58px;
                    }
                    input{
                        width: 300px;
                        height: 58px;
                        float: left;
                        background: none;
                        font-size: 18px;
                    }
                }

            }
        }
    }
</style>


<style>

    .about_box_big{
        width: 100%;
        border-top: 20px solid #f0f0f0;
        margin-top: 32px;
        margin-bottom: 50px;
        overflow: hidden;
    }
    /*关于我们*/
    .about_box{
        width: 1200px;
        margin: 0 auto ;

    }
    .left-tab,
    .right-content {
        background: #fff;
        margin-top: 20px;
        height: 820px;
        overflow: auto;
    }

    .right-content {
        width: 940px;
    }

    .left-tab {
        width: 250px;
        margin-right: 10px;
        text-align: center;
    }

    .left-tab li:first-child {
        margin-top: 30px;
    }

    .left-tab li {
        height: 50px;
        line-height: 50px;
        font-size: 18px;
        cursor: pointer;
        position: relative;
    }
    .btn{
        margin-right: 20px;
    }
    .active {
        background: #FFEDED;
        color: #E60026;
        border-left: 4px solid #f00;
    }

    /*.active:before {*/
    /*    content: "";*/
    /*    width: 10px;*/
    /*    height: 50px;*/
    /*    background: #E60026;*/
    /*    position: absolute;*/
    /*    top: 0;*/
    /*    left: 0;*/
    /*}*/

    .right-content li {
        display: none;
    }

    .right-content li:first-child {
        display: block;
    }

    .tab-title {
        margin: 0 50px;
        padding: 54px 0 26px;
        border-bottom: 1px solid #F7F5F5;
        font-size: 20px;
        text-align: left;
    }

    .tab-text,
    .tab-contact {
        margin: 30px 50px;
        height: 648px;
        overflow: auto;
    }

    .tab-text h2 {
        text-align: center;
        font-size: 20px;
        margin-bottom: 20px;
    }

    .tab-text p {
        font-size: 14px;
        line-height: 30px;
    }

    .tab-contact {
        display: flex;
    }

    .tab-contact img {
        width: 74px;
        height: 64px;
        margin: 10px 38px 0 0;
    }

    .tab-contact p {
        margin-bottom: 7px;
    }

    .tab-contact .red {
        color: #E60026 !important;
        font-size: 26px
    }

    .tab-contact p:first-child {
        font-size: 18px
    }

    .textarea-wrap {
        width: 848px;
        height: 305px;
        border: 1px solid rgba(203, 203, 203, 1);
        margin: 50px auto;
        padding: 10px;
        position: relative;
    }

    .textarea-wrap #contentwordage {
        position: absolute;
        right: 20px;
        bottom: 20px;
    }

    .textarea-wrap textarea {
        width: 100%;
        height: 100%;
        border: 0;
        resize: none;
    }

    .red-btn {
        width: 200px;
        height: 40px;
        line-height: 40px;
        background: rgba(230, 0, 38, 1);
        border-radius: 4px;
        text-align: center;
        margin: 60px auto;
        color: #FFFFFF;
        cursor: pointer;
    }

    .big-img{
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        display: block;
    }

</style>
